<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="筛选条件" name='level'>
          <a-input-group compact style="width:370px">
            <a-select style="width: 30%" v-model:value="search.keytype">
              <a-select-option value="id">模板ID</a-select-option>
              <a-select-option value="brand_id">品牌商编号</a-select-option>
              <a-select-option value="brand_name">公司名称</a-select-option>
              <a-select-option value="brand_account">品牌商账号</a-select-option>
            </a-select>
            <a-input style="width: 70%" v-model:value="search.keyword" placeholder="请输入关键词" />
          </a-input-group>
        </a-form-item>
        <a-form-item label="提交时间" name='time'>
          <a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
        </a-form-item>
        <a-form-item label="状态" name='level'>
          <a-select v-model:value="search.status" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="1">
              待审核
            </a-select-option>
            <a-select-option :value="2">
              审核通过
            </a-select-option>
            <a-select-option :value="3">
              拒绝通过
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox" :scroll="{ x: 'max-content'}">
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                  <a href="javascript:;" v-has:fwContentAudiMore="()=>detail(record)">查看详情</a>
                </a-menu-item>
                <a-menu-item v-if="record.verify_status==1">
                  <a href="javascript:;" v-has:fwContentAudiExamine="()=>audi(record)">审核</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" v-has:fwContentAudiEdit="()=>edit(record)">编辑</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
    <a-modal v-model:visible="visible" :confirm-loading="confirmLoading" :footer="null" :title="audiData.type=='edit'?'编辑防伪认证':'防伪认证详情'" @ok="handleOk" :width="700">
      <a-row type="flex">
        <a-col flex="1 1 200px">
          <a-form :model='audiData' ref="audiForm" :label-col="{span:5}" :wrapper-col="{span:10}">
            <a-form-item label="认证ID">
              {{audiData.id}}
            </a-form-item>
            <a-form-item label="认证名称">
              {{audiData.name}}
            </a-form-item>
            <a-form-item label="公司名称">
              {{audiData.brand_company}}
            </a-form-item>
            <a-form-item label="首次扫码提示">
              <a-input v-model:value="audiData.first_tips_title" :disabled="audiData.type=='detail'"></a-input>
            </a-form-item>
            <a-form-item label="首次扫码提示语">
              <a-textarea class="mt15" v-model:value="audiData.first_tips_content" :disabled="audiData.type=='detail'" placeholder="请输入拒绝的原因" />
            </a-form-item>
            <a-form-item label="重复扫码提示">
              <a-input v-model:value="audiData.repeat_tips_title" :disabled="audiData.type=='detail'"></a-input>
            </a-form-item>
            <a-form-item label="重复扫码提示语">
              <a-textarea class="mt15" v-model:value="audiData.repeat_tips_content" :disabled="audiData.type=='detail'" placeholder="请输入拒绝的原因" />
            </a-form-item>
            <a-form-item label="防伪认证审核" v-if="audiData.verify_status&&audiData.type!='edit'">
              <a-radio-group v-model:value="audiData.verify_status" @change="onRadioChange" :disabled="audiData.type=='detail'">
                <a-radio :value="2">
                  通过
                </a-radio>
                <a-radio :value="3">
                  拒绝
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="拒绝原因" v-if='audiData.verify_status == 3&&audiData.type!="edit"'>
              <a-textarea class="mt15" v-model:value="audiData.verify_msg" :disabled="audiData.type=='detail'" placeholder="请输入拒绝的原因" auto-size />
            </a-form-item>
            <a-form-item>
              <a-button @click="visible=false" class="m-r-5">取消</a-button>
              <a-button @click="handleOk" type="primary" v-if="audiData.type!='detail'">确定</a-button>
            </a-form-item>
          </a-form>
        </a-col>
        <a-col>
          <div style="display: flex;flex-direction: column;justify-content: center;height: 100%;width: 100%;">
            <P>变量规则:</P>
            <p>
              <a-tooltip placement="topRight">
                <template #title>
                  <span>该参数直接读取您系统开户时的品牌商名称，点击放到提示中可自动获取提示。</span>
                </template>
                @公司名称@
                <QuestionCircleOutlined />
              </a-tooltip>
            </p>
            <p>
              <a-tooltip placement="topRight">
                <template #title>
                  <span>该参数为变量，根据首次的扫码时间显示。</span>
                </template>
                @扫码时间@
                <QuestionCircleOutlined />
              </a-tooltip>
            </p>
            <p>
              <a-tooltip placement="topRight">
                <template #title>
                  <span>该参数为变量，根据当前的累计扫码次数自动显示。</span>
                </template>
                @扫码次数@
                <QuestionCircleOutlined />
              </a-tooltip>
            </p>
            <p>
              <a-tooltip placement="topRight">
                <template #title>
                  <span>该参数为变量，根据首次扫码地区自动显示。</span>
                </template>
                @扫码地区@
                <QuestionCircleOutlined />
              </a-tooltip>
            </p>
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </a-layout>
</template>
<script>
import { DownOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, reactive, toRefs, inject } from "vue";
import { message } from "ant-design-vue";
import { getRouter } from "@/utils/app";
import { setPageState, getPageState } from "@/utils/pageState";
import { $iscode } from "@/utils/app";
import {
 antisFakeTemListSave,
  antisFakeTemList,
} from "@/api/brand";
// 初始化默认筛选项数值
let defSearch = {
  keywords: "",
  keytype: "id",
  start_time: "",
  end_time: "",
  page: 1,
  limit: 10,
  status: "",
};
export default {
  components: { DownOutlined, QuestionCircleOutlined },
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([{}]);
    const state = reactive({
      visible: false,
      confirmLoading: false,
      listLoading: false,
      total: 1,
      valueRadio: null,
      refuseText: "",
      time: [],
      brand_id: "",
      audiData: {},
    });
    let columns = [
      {
        title: "认证ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "认证名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "品牌商编号",
        dataIndex: "brand_no",
        key: "brand_no",
      },

      {
        title: "公司名称",
        dataIndex: "brand_company",
        key: "brand_company",
      },
      {
        title: "品牌商账号",
        dataIndex: "brand_account",
        key: "brand_account",
        ellipsis: true,
      },
      {
        title: "提交时间",
        dataIndex: "create_time",
        key: "create_time",
      },
      {
        title: "状态",
        dataIndex: "verify_status_title",
        key: "verify_status_title",
      },
      {
        title: "操作",
        key: "action",
        fixed:"right",
        width: 100,
        slots: { customRender: "action" },
      },
    ];
    const $Modal = inject("$Modal");
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData(search.value);
    };
    // 获取列表
    const initData = async (values) => {
      state.listLoading = true;
      try {
        let res = await antisFakeTemList(values).then((res) => res.data);
        state.listLoading = false;
        if ($iscode(res)) {
          data.value = res.data.data;
          state.total = res.data.total;
        } else {
          message.error(res.message);
        }
      } catch (e) {
        state.listLoading = false;
      }
    };

    // 时间筛选
    const onChange = (e) => {
      if (e.length > 0) {
        search.value.start_time = e[0];
        search.value.end_time = e[1];
      } else {
        search.value.start_time = "";
        search.value.end_time = "";
      }
    };
    onMounted(() => {
      // 查看是否有留存状态，有则替换
      let pageState = getPageState(getRouter().route.path);
      if (pageState) {
        search.value = Object.assign(search.value, pageState);
      }
      initData(search.value);
    });
    // 查看详情
    const audiData = ref({});
    const detail = (data) => {
      audiData.value = data;
      audiData.value.type = "detail"; //标识 详情用
      state.visible = true;
    };
    // 修改
    const edit = (data) => {
      audiData.value = data;
      audiData.value.type = "edit"; //标识 修改用
      state.visible = true;
    };
    const handleOk = async() => {
      let result=await antisFakeTemListSave(audiData.value).then(res=>res.data).catch(error=>error)
      if($iscode(result,true)){
        pageSearchChange()
      state.visible = false;

      }
      console.log(result)
    };
    const audi = (data) => {
      audiData.value = data;
      audiData.value.type = "audi"; //标识 修改用
      state.visible = true;
    };
    return {
      ...toRefs(state),
      search,
      data,
      columns,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      onChange,
      audi,
      detail,
      audiData,
      edit,
      handleOk,
    };
  },
};
</script>